import React from 'react'
import './HomepageMainHero.module.scss'
import {
  MASCOT_CHEETAH_IMAGE,
  CHECK_CIRCLE_SOLID_ICON,
} from '../../../shared/constants';

const HomepageMainHero = ({ items }) => {

  return (
    <div className='hero-wrapper'>
      <div className='hero-container'>
        <div className='hero-first-section'>
          <div className='hero-title-wrapper'>
            <h1 className='hero-title'>Marketplace Asuransi Terbaik di Indonesia. </h1>
            <p className='hero-subtitle'>Beneran Pilihannya, Beneran Hematnya</p>
          </div>
          <img fetchPriority='high' className='hero-mascot' src={MASCOT_CHEETAH_IMAGE} alt="" />
        </div>
        {/* USP (Unique Selling Point) Section */}
        <div className='hero-second-section'>
          <div className='benefit-list-wrapper'>
            <div className='benefit-list'>
              <div className='benefit-item'>
                <img src={CHECK_CIRCLE_SOLID_ICON} className='benefit-item-icon' alt="" />
                <span className='benefit-item-label'>Diskon Maksimal</span>
              </div>
              <div className='benefit-item'>
                <img src={CHECK_CIRCLE_SOLID_ICON} className='benefit-item-icon' alt="" />
                <span className='benefit-item-label'>Proses Asuransi Serba Online</span>
              </div>
              <div className='benefit-item'>
                <img src={CHECK_CIRCLE_SOLID_ICON} className='benefit-item-icon' alt="" />
                <span className='benefit-item-label'>Konsultasi Gratis</span>
              </div>
            </div>
          </div>
          <div className='insurance-types'>
            {
              items.map((item, index) => (
                <div
                  className='insurance-item'
                  onClick={item.onClick}
                  key={`insurance-type-${index}`}
                >
                  <img
                    src={item.icon}
                    className='insurance-item-icon' />
                  <span
                    className='insurance-item-label'>
                    {item.title}
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomepageMainHero;